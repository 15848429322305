import React from 'react'
import { css } from 'emotion'
import { COLOR } from 'f-web/src/styles'

export default function FText({ children, style: customStyle, className: customClassName, id, ...props }) {
  // 1. Apply base style
  const style = [styles.baseStyle]

  // 2. Apply prop styles
  const keys = Object.keys(props)
  for (const key of keys) {
    if (props[key] === true && styles[key]) {
      style.push(styles[key])
    }
    switch (key) {
      case 'color':
      case 'textDecoration':
      case 'fontSize':
      case 'whiteSpace':
      case 'lineHeight':
      case 'cursor':
      case 'fontWeight':
      case 'textAlign':
      case 'numberOfLines':
      case 'letterSpacing':
      case 'zIndex':
        style.push(css({ [key]: props[key] }))
        break

      case 'backgroundText':
      case 'cardText':
      case 'primaryText':
        if (props[key] === true) {
          style.push(css({ color: COLOR[key] }))
        }
        break

      case 'primary':
      case 'primary75':
      case 'primary50':
      case 'primary25':
      case 'primary10':
      case 'secondary':
      case 'secondary75':
      case 'secondary50':
      case 'secondary25':
      case 'secondary10':
      case 'success':
      case 'success75':
      case 'success50':
      case 'success25':
      case 'success10':
      case 'warning':
      case 'warning75':
      case 'warning50':
      case 'warning25':
      case 'warning10':
      case 'error':
      case 'error75':
      case 'error50':
      case 'error25':
      case 'error10':
        if (props[key] === true) {
          style.push(css({ color: COLOR[key] }))
        }
        break

      default:
        break
    }
  }

  // 3. Apply customStyle passed down as this.props.style
  style.push(customStyle)
  style.push(customClassName)

  return (
    <span className={css(style)} id={id}>
      {children}
    </span>
  )
}

const styles = {
  baseStyle: css({
    fontFamily: 'Roboto, sans-serif',
  }),

  content: css({
    fontFamily: 'Roboto, sans-serif',
  }),
  heading: css({
    fontFamily: 'Montserrat, sans-serif',
  }),
  heading2: css({
    fontFamily: 'serif',
  }),

  antonio: css({ fontFamily: 'Antonio' }),
  dafoe: css({ fontFamily: 'Mr Dafoe' }),
  din: css({ fontFamily: 'D-DIN Condensed' }),
  futura: css({ fontFamily: 'Futura' }),
  painting: css({ fontFamily: 'Edo SZ' }),
  roboto: css({ fontFamily: 'Roboto' }),
  tech: css({ fontFamily: 'Hemi Head' }),
  poppins: css({ fontFamily: 'Poppins' }),
  rubik: css({ fontFamily: 'Rubik' }),

  black: css({ color: COLOR.black }),
  black75: css({ color: COLOR.black75 }),
  black50: css({ color: COLOR.black50 }),
  black25: css({ color: COLOR.black25 }),
  black10: css({ color: COLOR.black10 }),
  grey: css({ color: COLOR.grey }),
  grey75: css({ color: COLOR.grey75 }),
  grey50: css({ color: COLOR.grey50 }),
  grey25: css({ color: COLOR.grey25 }),
  grey10: css({ color: COLOR.grey10 }),
  lightGrey: css({ color: COLOR.lightGrey }),
  lightGrey75: css({ color: COLOR.lightGrey75 }),
  lightGrey50: css({ color: COLOR.lightGrey50 }),
  lightGrey25: css({ color: COLOR.lightGrey25 }),
  lightGrey10: css({ color: COLOR.lightGrey10 }),
  white: css({ color: COLOR.white }),
  white75: css({ color: COLOR.white75 }),
  white50: css({ color: COLOR.white50 }),
  white25: css({ color: COLOR.white25 }),
  white10: css({ color: COLOR.white10 }),
  transparent: css({ color: COLOR.transparent }),

  h1: css({ fontSize: '9.6rem' }),
  h2: css({ fontSize: '6rem' }),
  h3: css({ fontSize: '4.8rem' }),
  h4: css({ fontSize: '3.4rem' }),
  h5: css({ fontSize: '2.4rem' }),
  h6: css({ fontSize: '2rem' }),
  h7: css({ fontSize: '1.6rem' }),
  h8: css({ fontSize: '1.4rem' }),
  h9: css({ fontSize: '1.2rem' }),
  h10: css({ fontSize: '1rem' }),

  xBold: css({ fontWeight: '900' }),
  bold: css({ fontWeight: '700' }),
  thin: css({ fontWeight: '300' }),
  xThin: css({ fontWeight: '100' }),

  textLeft: css({ textAlign: 'left' }),
  textCenter: css({ textAlign: 'center' }),
  textRight: css({ textAlign: 'right' }),

  noSelect: css({
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),

  noWrap: css({
    whiteSpace: 'nowrap',
  }),
}
