import React, { Component } from 'react'
import { FView, FText } from 'components'
import { css } from 'emotion'
import { sortBy, get } from 'lodash'

export default class ProductTable extends Component {
  render() {
    const productsWithSubTotals = {}
    for (const order of this.props.orders) {
      const products = Object.values(order.products)
      for (const product of products) {
        productsWithSubTotals[product.id] = {
          name: product.name,
          id: product.id,
          price: product.price,
          count: get(productsWithSubTotals[product.id], 'count', 0) + product.count,
          categoryId: product.categoryId,
          subTotal: get(productsWithSubTotals[product.id], 'subTotal', 0) + product.price * product.count,
        }
      }
    }
    const sortedProductsBySubTotal = sortBy(productsWithSubTotals, 'subTotal').reverse()
    return (
      <FView h="84rem" w="100%" className={styles.container}>
        <FView h={40} alignCenter justifyBetween row>
          <FView w="30rem">
            <FText bold heading dark h6>
              {'Product Name'}
            </FText>
          </FView>
          <FText bold heading dark h6>
            {'SubTotal'}
          </FText>
          <FText bold heading dark h6>
            {'Count'}
          </FText>
        </FView>
        {sortedProductsBySubTotal.map((product, index) => {
          return (
            <FView
              w="100%"
              p="2rem"
              justifyBetween
              alignCenter
              row
              bg={index % 2 === 0 ? 'grey' : 'white'}
              className={styles.orderItem}
            >
              <FView w="26rem">
                <FText dark h6>
                  {product.name}
                </FText>
              </FView>
              <FView>
                <FText dark h6>
                  {product.subTotal.toFixed(2)}
                </FText>
              </FView>
              <FView>
                <FText dark h6>
                  {product.count}
                </FText>
              </FView>
            </FView>
          )
        })}
      </FView>
    )
  }
}

const styles = {
  container: css({
    overflowY: 'scroll',
  }),
  orderItem: css({}),
}
