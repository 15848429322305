import React, { Component } from 'react'
import { FView, FText } from 'components'
import { css } from 'emotion'
import { sortBy, get } from 'lodash'

export default class RatingTable extends Component {
  render() {
    const productsWithRatings = {}
    this.props.orders.forEach(order => {
      Object.values(order.products).forEach(product => {
        let rating = 0
        if (order.review) {
          rating = get(order.review.ratings, product.id, 0)
        }
        if (rating > 0) {
          productsWithRatings[product.id] = {
            name: product.name,
            id: product.id,
            totalRating: get(productsWithRatings[product.id], 'totalRating', 0) + rating,
            ratingCount: get(productsWithRatings[product.id], 'ratingCount', 0) + 1,
            count: get(productsWithRatings[product.id], 'count', 0) + product.count,
          }
        }
      })
      Object.values(order.rewards).forEach(reward => {
        let rating = 0
        if (order.review) {
          rating = get(order.review.ratings, reward.productId, 0)
        }
        if (rating > 0) {
          productsWithRatings[reward.productId] = {
            name: reward.name,
            id: reward.productId,
            totalRating: get(productsWithRatings[reward.productId], 'totalRating', 0) + rating,
            ratingCount: get(productsWithRatings[reward.productId], 'ratingCount', 0) + 1,
            count: get(productsWithRatings[reward.productId], 'count', 0) + reward.count,
          }
        }
      })
    })
    Object.keys(productsWithRatings).forEach(id => {
      if (productsWithRatings[id].ratingCount > 0) {
        productsWithRatings[id].rating = productsWithRatings[id].totalRating / productsWithRatings[id].ratingCount
      } else {
        productsWithRatings[id].rating = 0
      }
    })
    const sortedProductsByRating = sortBy(productsWithRatings, 'rating').reverse()
    return (
      <FView h="84rem" w="100%" className={styles.container}>
        <FView h={40} alignCenter justifyBetween row>
          <FView w="30rem">
            <FText bold heading dark h6>
              {'Product Name'}
            </FText>
          </FView>
          <FText bold heading dark h6>
            {'Avg Rating'}
          </FText>
          <FText bold heading dark h6>
            {'Count'}
          </FText>
        </FView>
        {sortedProductsByRating.map((product, index) => {
          return (
            <FView
              w="100%"
              p="2rem"
              justifyBetween
              alignCenter
              row
              bg={index % 2 === 0 ? 'grey' : 'white'}
              className={styles.orderItem}
            >
              <FView w="26rem">
                <FText dark h6>
                  {product.name}
                </FText>
              </FView>
              <FView>
                <FText dark h6>
                  {product.rating.toFixed(2)}
                </FText>
              </FView>
              <FView>
                <FText dark h6>
                  {product.ratingCount}
                </FText>
              </FView>
            </FView>
          )
        })}
      </FView>
    )
  }
}

const styles = {
  container: css({
    overflowY: 'scroll',
  }),
  orderItem: css({}),
}
