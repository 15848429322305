import React from 'react'
import { css } from 'emotion'

const Page404 = () => {
  return (
    <div className={styles.container}>
      <div>Oops! This page doesn't exist!</div>
    </div>
  )
}

const styles = {
  container: css({
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '12rem',
    fontSize: 28,
  }),
}

export default Page404
