import React, { useRef } from 'react'
import { Card, FInput, FText, FView, PageHeader, PrimaryButton } from 'components'
import * as api from 'api'

const Auth = () => {
  const emailInput = useRef()
  const passwordInput = useRef()

  const _handleLoginPress = () => {
    const email = emailInput.current.value
    const password = passwordInput.current.value
    api.auth.signInWithEmailAndPassword(email, password)
  }

  return (
    <div>
      <PageHeader />
      <FView w="100%" h="80vh" row justifyCenter alignCenter>
        <Card h="30rem" w="48.5rem" p="4rem" column justifyAround>
          <FText h5 white>
            Authenticate
          </FText>
          <div>
            <FInput placeholder="Enter Email" ref={emailInput} />
            <FView h="1rem" />
            <FInput type="password" placeholder="Enter Password" ref={passwordInput} />
          </div>
          <PrimaryButton onClick={_handleLoginPress} label="Login" />
        </Card>
      </FView>
    </div>
  )
}

export default Auth
