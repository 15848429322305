import React from 'react'
import { Line } from 'react-chartjs-2'
import { PrimaryButton, FView, Space } from 'components'
import moment from 'moment'

const dataTypes = {
  Charges: 'total',
  'Container Charges': 'total',
  'Custom Charges': 'total',
  Revenue: 'revenue',
  Commission: 'commissionAmount',
  'Rewards Value': 'discount',
  'Points Used': 'pointsUsed',
}

export default class OrderChart extends React.Component {
  state = {
    aggregateType: 'day',
  }
  render() {
    const orderData = {}
    let firstDate, lastDate
    for (const order of this.props.orders) {
      const dateNum = moment(order.createdAt)
        .startOf(this.state.aggregateType)
        .valueOf()
      if (firstDate == null || firstDate > dateNum) {
        firstDate = dateNum
      }
      if (lastDate == null || lastDate < dateNum) {
        lastDate = dateNum
      }
      if (this.props.dataType === 'Cancelled Orders') {
        if (order.status === 'Cancelled') {
          orderData[dateNum] = (orderData[dateNum] || 0) + 1
        }
      } else {
        if (order.status !== 'Cancelled') {
          if (this.props.dataType === 'Orders') {
            orderData[dateNum] = (orderData[dateNum] || 0) + 1
          } else if (this.props.dataType === 'Rewards') {
            let rewardsCount = 0
            if (order.rewards) {
              Object.values(order.rewards).forEach(reward => {
                rewardsCount += reward.count
              })
            }
            orderData[dateNum] = (orderData[dateNum] || 0) + rewardsCount
          } else if (this.props.dataType === 'Profit') {
            if (order.commissionAmount > 0) {
              orderData[dateNum] = (orderData[dateNum] || 0) + order.commissionAmount - (order.total * 0.029 + 0.3)
            } else {
              orderData[dateNum] =
                (orderData[dateNum] || 0) +
                order.total * this.props.restaurants[order.restaurantId].commissionFee -
                (order.total * 0.029 + 0.3)
            }
          } else if (this.props.dataType === 'Commission') {
            if (order.commissionAmount > 0) {
              orderData[dateNum] = (orderData[dateNum] || 0) + order.commissionAmount
            } else {
              orderData[dateNum] =
                (orderData[dateNum] || 0) + order.total * this.props.restaurants[order.restaurantId].commissionFee
            }
          } else {
            if (this.props.dataType === 'Container Charges') {
              if (order.sourceClient.startsWith('container')) {
                orderData[dateNum] = (orderData[dateNum] || 0) + order[dataTypes[this.props.dataType]]
              }
            } else if (this.props.dataType === 'Custom Charges') {
              if (!order.sourceClient.startsWith('container')) {
                orderData[dateNum] = (orderData[dateNum] || 0) + order[dataTypes[this.props.dataType]]
              }
            } else {
              orderData[dateNum] = (orderData[dateNum] || 0) + order[dataTypes[this.props.dataType]]
            }
          }
        }
      }
    }
    const labelsObj = {}
    {
      let date = firstDate
      while (date <= lastDate) {
        labelsObj[date] = moment(date).format('M/D')
        date = moment(date)
          .add(1, this.state.aggregateType + 's')
          .valueOf()
      }
    }
    const labelskeys = Object.keys(labelsObj)
    let total = 0
    const aggregateData = labelskeys.map(dateNum => {
      total = (orderData[dateNum] || 0) + total
      return total.toFixed(2)
    })
    const chartData = {
      labels: Object.values(labelsObj),
      datasets: [
        {
          backgroundColor: 'rgba(44,44,192,0.4)',
          borderColor: 'rgba(44,44,192,1)',
          label: this.props.dataType,
          data: labelskeys.map(dateNum => (orderData[dateNum] || 0).toFixed(2)),
        },
        {
          label: `${this.props.dataType} Growth`,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          data: aggregateData,
        },
      ],
    }
    return (
      <FView column>
        <Line data={chartData} />
        <FView row justifyCenter>
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'day' })}
            disabled={this.state.aggregateType === 'day'}
            label="Days"
          />
          <Space w2 />
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'week' })}
            disabled={this.state.aggregateType === 'week'}
            label="Weeks"
          />
          <Space w2 />
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'month' })}
            disabled={this.state.aggregateType === 'month'}
            label="Months"
          />
        </FView>
      </FView>
    )
  }
}
