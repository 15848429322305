import React from 'react'
import { Line } from 'react-chartjs-2'
import { FView } from 'components'

export default class FrequencyChart extends React.Component {
  render() {
    const customers = Object.values(this.props.orders).reduce((acc, order) => {
      const { userId } = order
      if (order.status !== 'Cancelled') {
        if (!acc[userId]) {
          acc[userId] = {}
          acc[userId].id = userId
          acc[userId].name = this.props.users[userId].name
          acc[userId].email = this.props.users[userId].email
          acc[userId].phoneNumber = this.props.users[userId].phoneNumber
          acc[userId].lastLogin = this.props.users[userId].lastLogin
        }
        acc[userId].revenue = (acc[userId].revenue || 0) + order.total
        acc[userId].orders = (acc[userId].orders || 0) + 1
      }
      return acc
    }, {})
    const frequency = Object.values(customers).reduce((acc, user) => {
      const { orders } = user
      if (!acc[orders]) {
        acc[orders] = 0
      }
      acc[orders] = acc[orders] + 1
      return acc
    }, {})
    const chartData = {
      labels: Object.keys(frequency),
      datasets: [
        {
          label: 'Order Frequency',
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          data: Object.values(frequency),
        },
      ],
    }
    return (
      <FView column>
        <Line data={chartData} />
      </FView>
    )
  }
}
