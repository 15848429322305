import React, { Component } from 'react'
import { FView, FText } from 'components'
import { css } from 'emotion'
import { sortBy } from 'lodash'
import moment from 'moment'

export default class CustomerList extends Component {
  render() {
    const customers = Object.values(this.props.orders).reduce((acc, order) => {
      const { userId } = order
      if (order.status !== 'Cancelled') {
        if (!acc[userId]) {
          acc[userId] = {}
          acc[userId].id = userId
          acc[userId].name = this.props.users[userId].name
          acc[userId].email = this.props.users[userId].email
          acc[userId].phoneNumber = this.props.users[userId].phoneNumber
          acc[userId].lastLogin = this.props.users[userId].lastLogin
            ? this.props.users[userId].lastLogin
            : this.props.users[userId].createdAt
        }
        acc[userId].revenue = (acc[userId].revenue || 0) + order.total
        acc[userId].discount = (acc[userId].discount || 0) + order.discount
        acc[userId].orders = (acc[userId].orders || 0) + 1
        acc[userId].pointsEarned = (acc[userId].pointsEarned || 0) + order.pointsEarned
        acc[userId].pointsUsed = (acc[userId].pointsUsed || 0) + order.pointsUsed
        acc[userId].rewards = (acc[userId].rewards || 0) + Object.keys(order.rewards).length
        acc[userId].mobileCount =
          (acc[userId].mobileCount || 0) +
          (order.sourceClient === 'mobile' || order.sourceClient === 'mobile-web' ? 1 : 0)
        acc[userId].webCount = (acc[userId].webCount || 0) + (order.sourceClient === 'web' ? 1 : 0)
      }
      return acc
    }, {})
    return (
      <FView h="84rem" w="100%" className={styles.container}>
        {sortBy(Object.values(customers), ['revenue', 'orders'])
          .reverse()
          .map(user => {
            return (
              <FView key={user.id} w="100%" mb="2rem" p="2rem" row className={styles.orderItem}>
                <FView column fill>
                  <FText h7>Name: {user.name}</FText>
                  <FText h7>Email: {user.email}</FText>
                  <FText h7>Phone Number: {user.phoneNumber}</FText>
                  <FText h7>Mobile: {user.mobileCount}</FText>
                  <FText h7>Web: {user.webCount}</FText>
                  <FText h7>
                    Last Login: {`${moment(user.lastLogin).format('LT')}, ${moment(user.lastLogin).format('DD/MM/YY')}`}
                  </FText>
                </FView>
                <FView column fill>
                  <FText h7>Revenue: ${user.revenue.toFixed(2)}</FText>
                  <FText h7>Orders: {user.orders}</FText>
                  <FText h7>Discount: ${user.discount.toFixed(2)}</FText>
                  <FText h7>Rewards: {user.rewards}</FText>
                  <FText h7>Points Earned: {user.pointsEarned}</FText>
                  <FText h7>Points Used: {user.pointsUsed}</FText>
                  <FText h7>Current Points: {user.pointsEarned - user.pointsUsed}</FText>
                </FView>
              </FView>
            )
          })}
      </FView>
    )
  }
}

const styles = {
  container: css({
    overflowY: 'scroll',
  }),
  orderItem: css({
    borderColor: 'black',
    borderStyle: 'solid',
    borderRadius: '2rem',
  }),
}
