import React from 'react'
import { Line } from 'react-chartjs-2'
import { PrimaryButton, FView, Space } from 'components'
import moment from 'moment'

export default class UserChart extends React.Component {
  state = {
    aggregateType: 'day',
  }
  render() {
    const userData = {}
    let firstDate, lastDate
    for (const user of this.props.users) {
      const time =
        this.props.selectedRestaurantId === 'all'
          ? user[this.props.dataType]
            ? user[this.props.dataType]
            : user.createdAt
          : user.restaurants[this.props.selectedRestaurantId][this.props.dataType]
      const dateNum = moment(time)
        .startOf(this.state.aggregateType)
        .valueOf()
      if (firstDate == null || firstDate > dateNum) {
        firstDate = dateNum
      }
      if (lastDate == null || lastDate < dateNum) {
        lastDate = dateNum
      }
      userData[dateNum] = (userData[dateNum] || 0) + 1
    }
    const labelsObj = {}
    {
      let date = firstDate
      while (date <= lastDate) {
        labelsObj[date] = moment(date).format('M/D')
        date = moment(date)
          .add(1, this.state.aggregateType + 's')
          .valueOf()
      }
    }
    const labelskeys = Object.keys(labelsObj)
    let total = 0
    const aggregateData = labelskeys.map(dateNum => {
      total = (userData[dateNum] || 0) + total
      return total.toFixed(2)
    })
    const chartData = {
      labels: Object.values(labelsObj),
      datasets: [
        {
          label: this.props.dataType === 'createdAt' ? 'Users' : 'Last Login',
          backgroundColor: 'rgba(44,44,192,0.4)',
          borderColor: 'rgba(44,44,192,1)',
          data: labelskeys.map(dateNum => userData[dateNum] || 0),
        },
        {
          label: this.props.dataType === 'createdAt' ? 'Users Growth' : 'Last Login Growth',
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          data: aggregateData,
        },
      ],
    }
    return (
      <FView column>
        <Line data={chartData} />
        <FView row justifyCenter>
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'day' })}
            disabled={this.state.aggregateType === 'day'}
            label="Days"
          />
          <Space w2 />
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'week' })}
            disabled={this.state.aggregateType === 'week'}
            label="Weeks"
          />
          <Space w2 />
          <PrimaryButton
            onClick={() => this.setState({ aggregateType: 'month' })}
            disabled={this.state.aggregateType === 'month'}
            label="Months"
          />
        </FView>
      </FView>
    )
  }
}
