import React, { Component } from 'react'
import { FView, FText } from 'components'
import { css } from 'emotion'
import moment from 'moment'

export default class OrderList extends Component {
  render() {
    return (
      <FView w="100%">
        {Object.values(this.props.orders)
          .reverse()
          .map(order => {
            const productItems = Object.values(order.products || order.orderCartItems) || []
            const rewardItems = Object.values(order.rewards) || []
            return (
              <FView key={order.id} w="100%" mb="2rem" p="2rem" row className={styles.orderItem}>
                <FView column w={200}>
                  <FText bold h7>
                    {this.props.restaurants[order.restaurantId].name}
                  </FText>
                  <FText h7>{`${moment(order.createdAt).format('LT')}, ${moment(order.createdAt).format(
                    'DD/MM/YY'
                  )}`}</FText>
                  <FText h7>Order #: {order.orderNumber}</FText>
                  <FText h7>OrderType: {order.orderType}</FText>
                  <FText h7>Order Status: {order.status}</FText>
                  <FText h7>Device: {order.sourceClient}</FText>
                  {!!order.tipAmount && <FText h7>Tip: ${order.tipAmount.toFixed(2)}</FText>}
                  <FText h7>Total: ${order.total.toFixed(2)}</FText>
                  <FText h7>Commission: ${order.commissionAmount.toFixed(2)}</FText>
                  <FText h7>Merchant Revenue: ${order.revenue}</FText>
                  {!!order.depositModifyAmount && <FText h7>Deposit Modify: ${order.depositModifyAmount}</FText>}
                  {!!order.connectedAccountDestinationAmount && (
                    <FText h7>Merchant Transfer: ${order.connectedAccountDestinationAmount}</FText>
                  )}
                </FView>
                <FView column ml="1rem" fill={1}>
                  {productItems.map((product, index) => {
                    return <FText key={index} h7>{`${product.count} ${product.name}`}</FText>
                  })}
                  {rewardItems.map((reward, index) => {
                    return <FText key={index} h7>{`${reward.count} (R) ${reward.name}`}</FText>
                  })}
                </FView>
                <FView column ml="1rem" fill>
                  <FText h7>Name: {order.name}</FText>
                  <FText h7>Email: {order.email}</FText>
                  <FText h7>Phone Number: {order.phoneNumber}</FText>
                  {!!order.notes && <FText h7>Notes: {order.notes}</FText>}
                  {!!order.cancelReason && <FText h7>Cancel: {order.cancelReason}</FText>}
                  {!!order.deliveryAddress && <FText h7>Del. Address: {order.deliveryAddress}</FText>}
                  {!!order.deliveryUnit && <FText h7>Del. Unit: {order.deliveryUnit}</FText>}
                  {!!order.deliveryInstructions && <FText h7>Del. Instr.: {order.deliveryInstructions}</FText>}
                  {!!order.pointsUsed && <FText h7>Points Used: {order.pointsUsed}</FText>}
                  {!!order.pointsEarned && <FText h7>Points Earned: {order.pointsEarned}</FText>}
                  {!!order.fPointsEarned && <FText h7>FPoints Earned: {order.fPointsEarned}</FText>}
                </FView>
              </FView>
            )
          })}
      </FView>
    )
  }
}

const styles = {
  orderItem: css({
    borderColor: 'black',
    borderStyle: 'solid',
    borderRadius: '2rem',
  }),
}
