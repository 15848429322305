import React from 'react'
import { getUsersCollection } from 'f-core/src/config/firebase'

const useUsersState = ({ from, to } = {}) => {
  const [usersStateRefreshing, setUsersStateRefreshing] = React.useState(false)
  const [usersState, setUsersState] = React.useState({})
  const [createdAtUsersState, setCreatedAtUsersState] = React.useState({})
  const [lastLoginUsersState, setLastLoginUsersState] = React.useState({})

  function refreshUsersState() {
    setUsersStateRefreshing(true)
    getUsersCollection()
      .get()
      .then(docs => {
        const newUsersState = {}
        docs.forEach(doc => {
          newUsersState[doc.id] = doc.data()
          newUsersState[doc.id].id = doc.id
        })
        setUsersState(newUsersState)
      })
    getUsersCollection()
      .where('lastLogin', '>', from.valueOf())
      .where('lastLogin', '<', to.valueOf())
      .get()
      .then(docs => {
        const newUsersState = {}
        docs.forEach(doc => {
          newUsersState[doc.id] = doc.data()
          newUsersState[doc.id].id = doc.id
        })
        setLastLoginUsersState(newUsersState)
      })
    getUsersCollection()
      .where('createdAt', '>', from.valueOf())
      .where('createdAt', '<', to.valueOf())
      .get()
      .then(docs => {
        const newUsersState = {}
        docs.forEach(doc => {
          newUsersState[doc.id] = doc.data()
          newUsersState[doc.id].id = doc.id
        })
        setCreatedAtUsersState(newUsersState)
      })
      .finally(() => {
        setUsersStateRefreshing(false)
      })
  }

  React.useEffect(() => {
    refreshUsersState()
  }, [])

  return { createdAtUsersState, lastLoginUsersState, usersState, usersStateRefreshing, refreshUsersState }
}

export default useUsersState
