import React from 'react'
import { Card, FButton, FText, FInput, FView, PageHeader } from 'components'
import { useRestaurantsState, useUsersState } from 'hooks'
import OrderChart from './OrderChart'
import OrderList from './OrderList'
import ProductTable from './ProductTable'
import UserChart from './UserChart'
import RatingTable from './RatingTable'
import CustomerList from './CustomerList'
import FrequencyChart from './FrequencyChart'
import moment from 'moment'
import { includes } from 'lodash'

const VISUALIZATION_TYPES = {
  ORDERS_LIST: 'Orders List',
  CUSTOMERS: 'Customers',
  CHARGES: 'Charges',
  CONTAINER_CHARGES: 'Container Charges',
  CUSTOM_CHARGES: 'Custom Charges',
  REVENUE: 'Revenue',
  COMMISSION: 'Commission',
  PROFIT: 'Profit',
  ORDERS: 'Orders',
  CANCELLED_ORDERS: 'Cancelled Orders',
  REWARDS: 'Rewards',
  REWARDS_VALUE: 'Rewards Value',
  POINTS_USED: 'Points Used',
  REGISTRATIONS: 'Registrations',
  LAST_LOGIN: 'Last Login',
  ORDER_FREQUENCY: 'Order Frequency',
  PRODUCTS: 'Products',
  RATINGS: 'Ratings',
}

const Dashboard = () => {
  const [selectedRestaurantId, setSelectedRestaurantId] = React.useState('all')
  const [selectedLocationId, setSelectedLocationId] = React.useState('all')

  const [fromMoment, setFromMoment] = React.useState(moment().startOf('day'))
  const [toMoment, setToMoment] = React.useState(moment().endOf('day'))

  const [visualizationType, setVisualizationType] = React.useState(VISUALIZATION_TYPES.ORDERS_LIST)

  const { restaurantsState, restaurantsStateRefreshing, refreshRestaurantState } = useRestaurantsState({
    from: fromMoment,
    to: toMoment,
  })

  const {
    createdAtUsersState,
    lastLoginUsersState,
    usersState,
    usersStateRefreshing,
    refreshUsersState,
  } = useUsersState({
    from: fromMoment,
    to: toMoment,
  })

  let containerMobileCount = 0
  let containerMobileWebCount = 0
  let containerWebCount = 0
  let containerTotalCharge = 0
  let containerOrdersCount = 0
  let containerCommission = 0
  let restaurantCharges = 0
  let restaurantOnlineCharges = 0
  let onlineDeliveryFee = 0
  let restaurantDeliveryFeeSubs = 0
  let onlineDeliveryFeeTax = 0
  let onlineDeliveryTip = 0
  let tip = 0
  let restaurantRevenue = 0
  let restaurantCommission = 0
  let restaurantRewards = 0
  let restaurantRewardCount = 0
  let mobileCount = 0
  let webCount = 0
  let cancelOrderCount = 0
  let orders = []
  let ordersCount = 0
  let createdAtUsers = []
  let lastLoginUsers = []
  let stripeFee = 0
  let addChargeCount = 0
  const selectedRestaurant = restaurantsState[selectedRestaurantId]

  if (selectedRestaurantId === 'all') {
    createdAtUsers = Object.values(createdAtUsersState)
    lastLoginUsers = Object.values(lastLoginUsersState)
    const restaurants = Object.values(restaurantsState)
    for (const restaurant of restaurants) {
      const restaurantOrders = Object.values(restaurant.Orders)
      orders = orders.concat(restaurantOrders)
      for (const order of restaurantOrders) {
        if (order.status !== 'Cancelled') {
          ordersCount++
          restaurantCharges += order.total
          if (order.paymentMethod.startsWith('online')) {
            restaurantOnlineCharges += order.total
          }
          onlineDeliveryFee += order.paymentStatus === 'paid-online' ? order.deliveryFee : 0
          restaurantDeliveryFeeSubs += order.restaurantDeliveryFeeSubs
          onlineDeliveryFeeTax += order.paymentStatus === 'paid-online' ? order.deliveryFeeTax : 0
          onlineDeliveryTip +=
            order.orderType === 'Delivery' && order.paymentStatus === 'paid-online' ? order.tipAmount : 0
          tip += order.tipAmount
          restaurantRevenue += order.revenue
          if (order.commissionAmount > 0) {
            restaurantCommission += order.commissionAmount
          }
          addChargeCount += order.addCharges ? order.addCharges.length : 0
          restaurantRewards += order.discount
          if (order.rewards) {
            const rewards = Object.values(order.rewards)
            for (const reward of rewards) {
              restaurantRewardCount += reward.count
            }
          }
          order.sourceClient === 'mobile' || order.sourceClient === 'mobile-web' ? mobileCount++ : webCount++
          stripeFee += order.total > 0 ? order.total * 0.029 + 0.3 : 0

          if (order.sourceClient.startsWith('container')) {
            containerOrdersCount++
            containerTotalCharge += order.total
            containerCommission += order.commissionAmount
            if (order.sourceClient === 'container-mobile') {
              containerMobileCount++
            } else if (order.sourceClient === 'container-web') {
              containerWebCount++
            } else if (order.sourceClient === 'container-mobile-web') {
              containerMobileWebCount++
            }
          }
        } else {
          cancelOrderCount++
        }
      }
    }
  } else {
    createdAtUsers = Object.values(createdAtUsersState).filter(user => {
      const restaurants = user.restaurants || {}
      return includes(Object.keys(restaurants), selectedRestaurantId)
    })
    lastLoginUsers = Object.values(lastLoginUsersState).filter(user => {
      const restaurants = user.restaurants || {}
      return includes(Object.keys(restaurants), selectedRestaurantId)
    })
    const selectedRestaurant = restaurantsState[selectedRestaurantId]
    orders = Object.values(selectedRestaurant.Orders)
    if (selectedLocationId === 'all') {
      for (const order of orders) {
        if (order.status !== 'Cancelled') {
          ordersCount++
          restaurantCharges += order.total
          if (order.paymentMethod.startsWith('online')) {
            restaurantOnlineCharges += order.total
          }
          onlineDeliveryFee += order.paymentStatus === 'paid-online' ? order.deliveryFee : 0
          restaurantDeliveryFeeSubs += order.restaurantDeliveryFeeSubs
          onlineDeliveryFeeTax += order.paymentStatus === 'paid-online' ? order.deliveryFeeTax : 0
          onlineDeliveryTip +=
            order.orderType === 'Delivery' && order.paymentStatus === 'paid-online' ? order.tipAmount : 0
          tip += order.tipAmount
          restaurantRevenue += order.revenue
          if (order.commissionAmount > 0) {
            restaurantCommission += order.commissionAmount
          }
          addChargeCount += order.addCharges ? order.addCharges.length : 0
          restaurantRewards += order.discount
          if (order.rewards) {
            const rewards = Object.values(order.rewards)
            for (const reward of rewards) {
              restaurantRewardCount += reward.count
            }
          }
          order.sourceClient === 'mobile' || order.sourceClient === 'mobile-web' ? mobileCount++ : webCount++
          stripeFee += order.total > 0 ? order.total * 0.029 + 0.3 : 0
          if (order.sourceClient.startsWith('container')) {
            containerOrdersCount++
            containerTotalCharge += order.total
            containerCommission += order.commissionAmount
            if (order.sourceClient === 'container-mobile') {
              containerMobileCount++
            } else if (order.sourceClient === 'container-web') {
              containerWebCount++
            }
          }
        } else {
          cancelOrderCount++
        }
      }
    } else {
      for (const order of orders) {
        if (order.locationId === selectedLocationId) {
          if (order.status !== 'Cancelled') {
            ordersCount = ordersCount + 1
            restaurantCharges += order.total
            tip += order.tipAmount
            restaurantRevenue += order.revenue
            if (order.commissionAmount > 0) {
              restaurantCommission += order.commissionAmount
            }
            addChargeCount += order.addCharges ? order.addCharges.length : 0
            restaurantRewards += order.discount
            if (order.rewards) {
              const rewards = Object.values(order.rewards)
              for (const reward of rewards) {
                restaurantRewardCount += reward.count
              }
            }
            order.sourceClient === 'mobile' || order.sourceClient === 'mobile-web' ? mobileCount++ : webCount++
            stripeFee += order.total > 0 ? order.total * 0.029 + 0.3 : 0
          } else {
            cancelOrderCount++
          }
        }
      }
    }
  }
  return (
    <div>
      <PageHeader />
      <FView row>
        <FView w="26rem" selfStretch bg="#333E4A">
          <FView h={'90vh'} overflowY="auto">
            <FView block>
              <FView
                style={{ cursor: 'pointer' }}
                h="6rem"
                pl="3rem"
                row
                alignCenter
                onClick={() => {
                  setSelectedRestaurantId('all')
                }}
              >
                <FText h6 white color={selectedRestaurantId === 'all' && '#EA8840'}>
                  ALL RESTAURANTS
                </FText>
              </FView>
              <FView>
                {Object.values(restaurantsState).map(restaurantState => {
                  const isOneOrMoreLocClosed = Object.values(restaurantState.Locations).reduce((prev, locationData) => {
                    if (!locationData.orderOpen) {
                      return true
                    }
                    return prev
                  }, false)

                  return (
                    <FView
                      style={{ cursor: 'pointer' }}
                      h="6rem"
                      pl="3rem"
                      row
                      alignCenter
                      key={restaurantState.id}
                      onClick={() => {
                        setSelectedRestaurantId(restaurantState.id)
                      }}
                    >
                      <FText h6 white color={restaurantState.id === selectedRestaurantId && '#EA8840'}>
                        {isOneOrMoreLocClosed && '[X] '}
                        {restaurantState.name}
                      </FText>
                    </FView>
                  )
                })}
              </FView>
            </FView>
          </FView>
        </FView>
        <FView fill row>
          <FView fill pt="2rem" pl="2rem" h={'90vh'} overflowY="scroll">
            <FView block>
              {(visualizationType === VISUALIZATION_TYPES.CHARGES ||
                visualizationType === VISUALIZATION_TYPES.CONTAINER_CHARGES ||
                visualizationType === VISUALIZATION_TYPES.CUSTOM_CHARGES ||
                visualizationType === VISUALIZATION_TYPES.REVENUE ||
                visualizationType === VISUALIZATION_TYPES.COMMISSION ||
                visualizationType === VISUALIZATION_TYPES.PROFIT ||
                visualizationType === VISUALIZATION_TYPES.ORDERS ||
                visualizationType === VISUALIZATION_TYPES.CANCELLED_ORDERS ||
                visualizationType === VISUALIZATION_TYPES.REWARDS ||
                visualizationType === VISUALIZATION_TYPES.REWARDS_VALUE ||
                visualizationType === VISUALIZATION_TYPES.POINTS_USED) && (
                <OrderChart orders={orders} restaurants={restaurantsState} dataType={visualizationType} />
              )}
              {visualizationType === VISUALIZATION_TYPES.ORDERS_LIST && (
                <OrderList orders={orders} restaurants={restaurantsState} />
              )}
              {visualizationType === VISUALIZATION_TYPES.PRODUCTS && (
                <ProductTable orders={orders} restaurants={restaurantsState} />
              )}
              {visualizationType === VISUALIZATION_TYPES.RATINGS && (
                <RatingTable orders={orders} restaurants={restaurantsState} />
              )}
              {visualizationType === VISUALIZATION_TYPES.REGISTRATIONS && (
                <UserChart users={createdAtUsers} selectedRestaurantId={selectedRestaurantId} dataType={'createdAt'} />
              )}
              {visualizationType === VISUALIZATION_TYPES.LAST_LOGIN && (
                <UserChart users={lastLoginUsers} selectedRestaurantId={selectedRestaurantId} dataType={'lastLogin'} />
              )}
              {visualizationType === VISUALIZATION_TYPES.CUSTOMERS && (
                <CustomerList orders={orders} users={usersState} />
              )}
              {visualizationType === VISUALIZATION_TYPES.ORDER_FREQUENCY && (
                <FrequencyChart orders={orders} users={usersState} />
              )}
            </FView>
          </FView>
          <FView w="30rem" h={'90vh'} overflowY="scroll">
            <FView block>
              <Card m="2rem" p="2rem">
                <FText h6 white>
                  Location
                </FText>
                <select value={selectedLocationId} onChange={event => setSelectedLocationId(event.target.value)}>
                  <option value={'all'}>All</option>
                  {!!selectedRestaurant &&
                    Object.values(selectedRestaurant.Locations).map(locationData => {
                      return (
                        <option value={locationData.id} key={locationData.id}>
                          {!locationData.orderOpen && '[X] '}
                          {locationData.locationName}
                        </option>
                      )
                    })}
                </select>
                <FView mt="1rem" mb="2rem">
                  <FView>
                    <FText h7 white>
                      From
                    </FText>
                    <FInput
                      type="date"
                      onChange={event => {
                        setFromMoment(moment(event.target.value, 'YYYY-MM-DD').startOf('day'))
                      }}
                      value={fromMoment.format('YYYY-MM-DD')}
                    />
                  </FView>
                  <FView>
                    <FText h7 white>
                      To
                    </FText>
                    <FInput
                      type="date"
                      onChange={event => {
                        setToMoment(moment(event.target.value, 'YYYY-MM-DD').endOf('day'))
                      }}
                      value={toMoment.format('YYYY-MM-DD')}
                    />
                  </FView>
                </FView>
                <FButton
                  onClick={() => {
                    refreshRestaurantState()
                    refreshUsersState()
                  }}
                  disabled={restaurantsStateRefreshing || usersStateRefreshing}
                >
                  <FView bg="white" p="1rem" row justifyCenter alignCenter>
                    <FText h7>{restaurantsStateRefreshing ? 'Refreshing...' : 'Apply'}</FText>
                  </FView>
                </FButton>
              </Card>
              <Card m="2rem" p="2rem">
                <FView>
                  <select onChange={e => setVisualizationType(e.target.value)} value={visualizationType}>
                    {Object.keys(VISUALIZATION_TYPES).map(key => (
                      <option key={key} value={VISUALIZATION_TYPES[key]}>
                        {VISUALIZATION_TYPES[key]}
                      </option>
                    ))}
                  </select>
                </FView>
                <FView size={10} />
                <FView mb="1rem">
                  <FText h6 white>
                    C Total Charge: ${containerTotalCharge.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C Orders Count: {containerOrdersCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C Avg Charge: $
                    {containerOrdersCount ? (containerTotalCharge / containerOrdersCount).toFixed(2) : '0'}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C Commission: ${containerCommission.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C Web Count: {containerWebCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C MobileWeb Count: {containerMobileWebCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    C Mobile Count: {containerMobileCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Tip: ${tip.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Total: ${restaurantCharges.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Online Charge: ${restaurantOnlineCharges.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Online Delivery Fee: ${onlineDeliveryFee.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Online Delivery Fee Tax: ${onlineDeliveryFeeTax.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Online Delivery Tip: ${onlineDeliveryTip.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Restaurant Delivery Fee Subs: ${restaurantDeliveryFeeSubs.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Commission: ${restaurantCommission.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Revenue: ${restaurantRevenue.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Stripe Fee: ${stripeFee.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Profit: ${(restaurantCommission - stripeFee).toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Orders: {ordersCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Cancelled Orders: {cancelOrderCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Avg Charge: ${ordersCount > 0 ? (restaurantCharges / ordersCount).toFixed(2) : 0}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Rewards: {restaurantRewardCount}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Rewards Value: ${restaurantRewards.toFixed(2)}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Mobile Ratio:
                    {mobileCount + webCount > 0 ? parseInt((mobileCount / (mobileCount + webCount)) * 100) : 0}%
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Cancel Ratio: {orders.length > 0 ? parseInt((cancelOrderCount / orders.length) * 100) : 0}%
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Add Charge Ratio: {orders.length > 0 ? parseInt((addChargeCount / orders.length) * 100) : 0}%
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Registrations: {createdAtUsers.length}
                  </FText>
                </FView>
                <FView mb="1rem">
                  <FText h6 white>
                    Last Login: {lastLoginUsers.length}
                  </FText>
                </FView>
              </Card>
            </FView>
          </FView>
        </FView>
      </FView>
    </div>
  )
}

export default Dashboard
