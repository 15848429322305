import React from 'react'
import { PageHeader } from 'components'
import { foodlyLogo } from 'images'
import { css } from 'emotion'

const Home = () => {
  return (
    <>
      <PageHeader />
      <div className={styles.container}>
        <img style={{ height: 100 }} src={foodlyLogo} alt="foodlyLogo" />
        <p className={styles.slogan}>{'The best ordering platform in the world.'}</p>
      </div>
    </>
  )
}

const styles = {
  container: css({
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  slogan: css({
    color: '#263238',
    fontSize: '35px',
    textAlign: 'center',
    lineHeight: '55px',
    fontWeight: 100,
  }),
  logo: css({
    height: '20rem',
    objectFit: 'contain',
  }),
  title: css({
    width: '45rem',
  }),
}

export default Home
