import React from 'react'
import {
  getRestaurantsCollection,
  getRestaurantLocationsCollection,
  getRestaurantOrdersCollection,
} from 'f-core/src/config/firebase'

const useRestaurantsState = ({ from, to } = {}) => {
  const [restaurantsStateRefreshing, setRestaurantsStateRefreshing] = React.useState(false)
  const [restaurantsState, setRestaurantsState] = React.useState({})

  const refreshRestaurantState = () => {
    setRestaurantsStateRefreshing(true)
    getRestaurantsCollection()
      .get()
      .then(docs => {
        docs.forEach(doc => {
          restaurantsState[doc.id] = doc.data()
          restaurantsState[doc.id].id = doc.id
          restaurantsState[doc.id].Locations = {}
          restaurantsState[doc.id].Orders = {}
          // get Restaurant Locations
          getRestaurantLocationsCollection({
            restaurantId: doc.id,
          })
            .get()
            .then(locationDocs => {
              const restaurantLocationsData = {}
              locationDocs.forEach(locationDoc => {
                restaurantLocationsData[locationDoc.id] = locationDoc.data()
                restaurantLocationsData[locationDoc.id].id = locationDoc.id
              })
              restaurantsState[doc.id].Locations = restaurantLocationsData
              setRestaurantsState(restaurantsState)
            })

          // get Restaurant Orders
          getRestaurantOrdersCollection({
            restaurantId: doc.id,
          })
            .where('createdAt', '>', from.valueOf())
            .where('createdAt', '<', to.valueOf())
            .get()
            .then(orderDocs => {
              setRestaurantsStateRefreshing(false)
              const restaurantOrdersData = {}
              orderDocs.forEach(orderDoc => {
                restaurantOrdersData[orderDoc.id] = orderDoc.data()
                restaurantOrdersData[orderDoc.id].id = orderDoc.id
              })
              restaurantsState[doc.id].Orders = restaurantOrdersData
              setRestaurantsState(restaurantsState)
            })
        })
        setRestaurantsState(restaurantsState)
      })
  }

  React.useEffect(() => {
    refreshRestaurantState()
  }, [])

  return {
    restaurantsState,
    restaurantsStateRefreshing,
    refreshRestaurantState,
  }
}

export default useRestaurantsState
