import React from 'react'
import { FView } from 'components'
import { css } from 'emotion'

const Card = props => {
  const { children, className, ...restProps } = props
  const classNames = css(styles.container, className)
  return (
    <FView className={classNames} {...restProps}>
      {children}
    </FView>
  )
}

const styles = {
  container: css({
    borderRadius: '1rem',
    backgroundColor: 'rgba(0,0,0,.6)',
  }),
}

export default Card
