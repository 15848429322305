import React from 'react'
import { FButton, FText } from 'components'
import { css } from 'emotion'

const PrimaryButton = props => {
  const { label, ...restProps } = props
  return (
    <FButton className={styles.button} {...restProps}>
      <FText h7 white bold center>
        {label}
      </FText>
    </FButton>
  )
}

const styles = {
  button: css({
    paddingLeft: '4rem',
    paddingRight: '4rem',
    height: '4.6rem',
    background: '#36B7EE',
    borderRadius: '0.8rem',
    textAlign: 'center',
  }),
}

export default PrimaryButton
