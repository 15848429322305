import React, { useEffect, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { PrimaryButton, FText, FView } from 'components'
import { foodlyLogo } from 'images'
import * as api from 'api'
import { firebaseAuth } from 'f-core/src/config/firebase'
import { css } from 'emotion'

const PageHeader = props => {
  const [isAuthed, setIsAuthed] = useState(false)

  useEffect(() => {
    return firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuthed(true)
        props.history.push('/dashboard')
      } else {
        setIsAuthed(false)
      }
    })
  }, [])

  const email = firebaseAuth.currentUser && firebaseAuth.currentUser.email
  return (
    <div className={styles.container}>
      <NavLink to="/">
        <img style={{ height: 50 }} src={foodlyLogo} alt="foodlyLogo" />
      </NavLink>
      <FView row alignCenter>
        <FView mr={'2rem'}>
          <FText h7>{email}</FText>
        </FView>
        {isAuthed ? (
          <PrimaryButton label="Logout" onClick={() => api.auth.signOut().then(() => props.history.push('/'))} />
        ) : (
          <NavLink to="/auth">
            <PrimaryButton label="Authenticate" />
          </NavLink>
        )}
      </FView>
    </div>
  )
}

const styles = {
  container: css({
    width: '100%',
    height: '8rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 .4rem .4rem rgba(0,0,0,0.2)',
    position: 'relative',
    zIndex: 1,
    padding: '0 4rem 0 4rem',
  }),
}

export default withRouter(PageHeader)
